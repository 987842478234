import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Logo from "../components/logo"
import SEO from "../components/seo"

export default ({ data }) => {
  return (
    <Layout>
      <SEO title="Brand" keywords={[`soso`, `sosolin`]} />
      <div className="brandGrid">
        <Logo />
        {data.allMdx.edges.map(({ node }) => (
          <div className="brandItem" key={node.id}>
            <Link to={node.frontmatter.path}>
              <img src={node.frontmatter.cover.publicURL} alt="" />
            </Link>
            {/* <div className="name">{node.frontmatter.title}</div> */}
            {/* <p className="description">{node.frontmatter.description}</p> */}
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PageList {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            path
            cover {
              publicURL
            }
            title
            description
            date
          }
        }
      }
    }
  }
`
